@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.logo {
  padding: 10px 20px;
}

#root,
.App {
  height: 100%;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-btn-primary:not([disabled]),
.ant-switch-checked {
  @apply bg-blue-600 border-0;
}
.ant-btn-primary:not([disabled]):hover {
  @apply bg-blue-400 border-0;
}

.ant-notification-notice,
.ant-notification-notice-error {
  background-color: black !important;
  border-radius: 8px;
}
.ant-notification-notice-message,
.ant-notification-notice-close {
  color: white !important;
}
.ant-notification-notice-description {
  color: #aaa !important;
}

button[role='switch'] {
  background-color: #cccccc !important;
  background-image: none !important;
}
button[role='switch'][area-checked='true'] {
  background-color: blue !important;
  background-image: none !important;
}
.ant-btn .anticon > svg {
  margin-top: -6px;
}

#__next {
  height: 100%;
}

.login-page,
.set-password-page,
.set-password-page .ant-row,
.login-page .ant-row {
  height: 100%;
}

.auth-side {
  background: url('../public/hutomo.jpg') cadetblue no-repeat;
  height: 100%;
  background-position: center center;
  background-size: cover;
  opacity: 0.6;
}
.auth-side-text {
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  padding: 100px;
  padding-top: 250px;
}
.auth-side-text h1,
.auth-side-text h3 {
  margin: 0px;
  margin-top: 50px !important;
  font-weight: bolder;
}

.auth-side-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #c5c5c5 !important;
}

.login-form-forgot {
  float: right;
}

.ant-switch-checked:not([disabled]) {
  background: #1890ff !important;
}

.avatar-checkbox .ant-avatar.selected {
  border: solid 2px blue;
}

.ant-avatar-group {
  margin-top: 5px;
}
.ant-avatar-group .ant-avatar {
  cursor: pointer;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -4px;
}

.ant-checkbox-disabled + span {
  color: #666 !important;
}

.breadcrumb {
  /*centering*/
  width: 100%;
  display: flex;
  /* box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.35); */
  overflow: hidden;
  border-radius: 5px;
  /*Lets add the numbers for each link using CSS counters. flag is the name of the counter. to be defined using counter-reset in the parent element of the links*/
  counter-reset: flag;
}

.breadcrumb a {
  text-decoration: none;
  outline: none;
  /* display: block; */
  float: left;
  font-size: 12px;
  color: white;
  /*need more margin on the left of links to accomodate the numbers*/
  padding: 5px 10px 5px 30px;
  background: #333;
  background: linear-gradient(#333, #111);
  position: relative;
  flex: 1;
}

.lead .breadcrumb a {
  line-height: 36px;
}

/*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
.breadcrumb a:first-child {
  padding-left: 46px;
  border-radius: 5px 0 0 5px; /*to match with the parent's radius*/
}
.breadcrumb a:first-child:before {
  left: 14px;
}
.breadcrumb a:last-child {
  border-radius: 0 5px 5px 0; /*this was to prevent glitches on hover*/
  padding-right: 20px;
}

/*hover/active styles*/
.breadcrumb a.active,
.breadcrumb a:hover {
  /* background: #111; */
  /* background: linear-gradient(#333, #111); */
}
.breadcrumb a.active:after,
.breadcrumb a:hover:after {
  /* background: #222; */
  /* background: linear-gradient(145deg, #333, #222); */
}

/*adding the arrows for the breadcrumbs using rotated pseudo elements*/
.breadcrumb a:after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px; /*half of square's length*/
  /*same dimension as the line-height of .breadcrumb a */
  width: 48px;
  height: 48px;
  /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's: 
	length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
	if diagonal required = 1; length = 1/1.414 = 0.707*/
  transform: scale(0.707) rotate(45deg);
  /*we need to prevent the arrows from getting buried under the next link*/
  z-index: 1;
  /*background same as links but the gradient will be rotated to compensate with the transform applied*/
  background: #ffffff;
  background: linear-gradient(135deg, #777, #333);
  /*stylish arrow design using box shadow*/
  box-shadow: 
		/* 1px -1px 0 2px rgba(0, 0, 0, 0.4),  */ 3px -3px 0 0px rgba(255, 255, 255, 1);
  /*
		5px - for rounded arrows and 
		50px - to prevent hover glitches on the border created using shadows*/
  border-radius: 0 5px 0 50px;
}
/*we dont need an arrow after the last link*/
.breadcrumb a:last-child:after {
  content: none;
}
/*we will use the :before element to show numbers*/
.breadcrumb a:before {
  /* content: counter(flag); */
  /* counter-increment: flag; */
  /*some styles now*/
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  /* background: #333; */
  /* background: linear-gradient(#333, #300); */
  font-weight: bold;
}

.flat a {
  text-align: center;
}

.flat a,
.flat a:after {
  background: #ddd;
  color: #888;
  transition: all 0.4s;
}
.flat a:before {
  /* background: #fff; */
  /* box-shadow: 0 0 0 1px rgb(248, 22, 135); */
}
.flat a:hover,
.flat a.active,
.flat a:hover:after,
.flat a.active:after {
  background: #0eb2f8;
  color: white;
  text-align: center;
}

.flat a:hover,
.flat a.active_error,
.flat a:hover:after,
.flat a.active_error:after {
  background: #b30000;
  color: white;
  text-align: center;
}

.flat a:hover,
.flat a.active_success,
.flat a:hover:after,
.flat a.active_success:after {
  background: #0a7d00;
  color: white;
  text-align: center;
}

.ant-alert-with-description span[role='img'] {
  position: relative;
  margin-top: 4px;
}
